import { default as currency9zECcOK4ZNMeta } from "/vercel/path0/pages/currency.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as interestC62fYcegPEMeta } from "/vercel/path0/pages/interest.vue?macro=true";
import { default as penaltytqff5hvueQMeta } from "/vercel/path0/pages/penalty.vue?macro=true";
export default [
  {
    name: "currency",
    path: "/currency",
    component: () => import("/vercel/path0/pages/currency.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "interest",
    path: "/interest",
    component: () => import("/vercel/path0/pages/interest.vue").then(m => m.default || m)
  },
  {
    name: "penalty",
    path: "/penalty",
    component: () => import("/vercel/path0/pages/penalty.vue").then(m => m.default || m)
  }
]