<script setup lang="ts">
useHead({
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' }
  ],
  htmlAttrs: {
    lang: 'en'
  }
})

useSeoMeta({
  ogImage: 'https://uimire/social-card.png',
  twitterImage: 'https://uimire/social-card.png',
  twitterCard: 'summary_large_image'
})
</script>

<template>
  <AppHeader class="noprint" />

  <UMain>
    <NuxtPage />
  </UMain>

  <AppFooter class="noprint" />

  <UNotifications />
</template>

<style>
.printonly {
  display: none;
}

@media print {
  .printonly {
    display: block;
  }

  .noprint {
    display: none;
  }
}
</style>