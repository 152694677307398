<script setup lang="ts">
const route = useRoute()

const links = computed(() => [
  {
    label: 'Acasa',
    to: '/',
    icon: 'i-heroicons-home',
  },
  {
    label: 'Penalitate',
    to: '/penalty',
  },
  {
    label: 'Dobanda',
    to: '/interest',
  },
])
</script>

<template>
  <UHeader :links="links">
    <template #logo>
      <Logo />
    </template>

    <!-- <template #right>
      <UButton label="Sign in" color="white" variant="ghost" trailing-icon="i-heroicons-arrow-right-20-solid"
        class="hidden lg:flex" />
    </template> -->

    <template #panel>
      <UAsideLinks :links="links" />
      <!-- 
      <UDivider class="my-6" />

      <UButton label="Sign in" color="white" block class="mb-3" />
      <UButton label="Get started" block />
      -->
    </template>
  </UHeader>
</template>
